import { ref } from "vue";

const ValidError = {
  label: "Предварительное условие не выполнено",
  message: "Пожалуйста, задайте фильтр по дате публикации",
}

const NotFoundError = {
  label: "Произошла ошибка при получении шаблона",
  message: "К сожалению, мы не смогли получить информацию о шаблоне, просмотр сохраненных фильтров по шаблону недоступен",
}

export const ErrorMessageMode = {
  ValidError: 'validationError',
  NotFoundError: 'notFoundError',
} as const

type ErrorMessageModeTypes = typeof ErrorMessageMode[keyof typeof ErrorMessageMode]

function getErrorAlert(mode: ErrorMessageModeTypes) {
  switch(mode) {
    case ErrorMessageMode.ValidError: return ValidError;
    case ErrorMessageMode.NotFoundError: return NotFoundError;
  }
}

export function useAnalyticsWarnings() {

  const errorMessage = ref();
  const showErrorMessage = ref(false);

  function openErrorAlert(mode: ErrorMessageModeTypes|undefined, message?: string) {
    if (!mode) showErrorMessage.value = false;
    else {
      errorMessage.value = getErrorAlert(mode);
      if (message) errorMessage.value.message = message;
      showErrorMessage.value = true;
    }
  }

  return {
    errorMessage,
    showErrorMessage,
    openErrorAlert,
  }
}
