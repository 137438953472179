<template lang="pug">
.industries
  .selector-wrapper
    template-selector(
      analytic-mode
      v-if="enableTemplates"
      v-model="selectedTemplatesIds"
      @check:template="fetchTemplateAnalyse"
    )
    ui-button.nowrap(
      v-if="enableTemplates"
      size="large"
      :disabled="!selectedTemplatesIds.length"
      :icon-right="showSearchForm ? UiIconNames.Chevron_Up : UiIconNames.Chevron_Down"
      @click="searchFormWrap"
    ) Расширенный анализ
    ui-button.nowrap(
      v-if="enableTemplates"
      size="large"
      :icon-left="UiIconNames.Landing_DataAnalysis"
      @click="newAnalyse"
    ) Новый анализ

  alert-message(
    v-if="isAdvancedModified && !showSearchForm && !isErrorsChecking"
    type="warning"
    label="Выбраны расширенные фильтры"
    :message="showDatesWarning && `В выбранном шаблоне не установлен или установлен частично фильтр по дате публикации. В таком случае фильтр устанавливается автоматически, с периодом в 1 год с ${ formatDateDMY(searchForm.datePublic.dateFrom) } до ${ formatDateDMY(searchForm.datePublic.dateTo) }`"
  )
    span.show-form-button(@click="setShowSearchForm(true)") Открыть панель фильтров

  alert-message(
    v-if="showErrorMessage && errorMessage"
    v-model:show="showErrorMessage"
    type="error"
    :label="errorMessage?.label"
    :message="errorMessage?.message"
  )

  search-form(
    tab="AnalyseIndustry"
    :show-hide-button="enableTemplates"
    v-model:activeTab="searchFormActiveTab"
    v-model:searchForm="searchForm"
    v-model:show-search-form="showSearchForm"
    @hide:search-form="setShowSearchForm(false)"
    @start:analyse="startAnalyse"
    @show:error="showError"
  )

  #analytics-results
    ui-loader(v-if="isErrorsChecking")
    template(v-else)
      average-cards(
        v-if="fetchMode !== 'none'"
        :cards="averageCards"
        :data="commonCards.data"
        :info="commonCards.info"
      )
      statistics(
        v-if="fetchMode !== 'none'"
        tab="AnalyseIndustry"
        :hide-line-chart="(monthlyChart.data || []).length < 2"
        :line-chart-data="monthlyChart"
        :map-chart-data="regionChart"
      )
      table-wrapper(
        v-if="fetchMode !== 'none'"
        tab="AnalyseIndustry"
        :tabs="industryTabs"
        :entities-list="entitiesLists"
        :entities-list-info="entitiesListsInfo"
        :sorting-panels="sortingPanels"
        :excel-loader="excelLoader"
        v-model:mode="mode"
        v-model:sorting-form="sortingForm"
        @reset-entity="resetEntity"
        @fetch-entity="fetchEntityPrev"
        @download-excel="downloadExcel"
      )
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, provide, ref } from "vue";
import { scrollIntoView, scrollToTop } from "@/utils/scroller/documentScroller";
import { useSearchTemplates } from "@/use/search/useSearchTemplates";
import { storeToRefs } from "pinia";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { assignSorting, tagsToIdList } from "@/utils/searchForm/searchFormWorker";
import { useAnalytics } from "@/use/analytics/useAnalytics";
import { downloadAnalyticExcel } from "@/utils/download/fileDownloader";
import { getDefaultSearchForm } from "@/utils/getters/defaultSearchForm";
import { formatDateDMY } from "@/utils/formatter/dateFormatter";
import isEqual from "lodash/isEqual";
import useAnalyticsIndustryStore from "@/stores/analytics/useAnalyticsIndustryStore";

import TemplateSelector from "@/components/selector/templateSelector/TemplateSelector.vue";
import AverageCards from "@/components/pages/analytics/statistics/average/AverageCards.vue";
import Statistics from "@/components/pages/analytics/statistics/Statistics.vue";
import TableWrapper from "@/components/pages/analytics/table/TableWrapper.vue";
import AlertMessage from "@/components/ui/alert/AlertMessage.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import SearchForm from "@/components/searchForm/SearchForm.vue";
import UiLoader from "@/components/ui/loader/UiLoader.vue";

import UiIconNames from "@/components/ui/icon/UiIconNames";
import { useApi } from "~/use/api/useApi";
import { getErrorMessage } from "~/use/users/useUserModifier";
import useTemplatesStore from "~/stores/templates/useTemplatesStore";
import { ErrorMessageMode, useAnalyticsWarnings } from "~/use/warnings/useAnalyticsWarnings";
import omit from "lodash/omit";

export default defineComponent({
  name: "AnalyticsIndustry",
  methods: {
    formatDateDMY,
  },
  components: {
    UiLoader,
    UiButton,
    AlertMessage,
    TemplateSelector,
    AverageCards,
    TableWrapper,
    Statistics,
    SearchForm,
  },
  setup() {

    const templatesStore = useTemplatesStore();

    const { enableTemplates } = useUserAccess();
    const showSearchForm = ref(!enableTemplates.value);
    const excelLoader = ref(false);

    const templateLoading = ref(false);
    const innerErrorChecking = ref(false);

    const searchFormActiveTab = ref('default');

    /** warnings */
    const showDatesWarning = ref(false);

    const {
      errorMessage,
      showErrorMessage,
      openErrorAlert,
    } = useAnalyticsWarnings()

    function showError(error: string) {
      openErrorAlert(ErrorMessageMode.ValidError, error)
      scrollToTop()
    }
    /** end warnings */

    const defaultSearchForm = getDefaultSearchForm('AnalyseIndustry')

    const isErrorsChecking = computed(() => innerErrorChecking.value || templateLoading.value);
    const isAdvancedModified = computed(() => {
      return !isEqual(
        omit(searchForm.value, ['industrySearchMode', 'wordSearchMode']),
        omit(defaultSearchForm, ['industrySearchMode', 'wordSearchMode']),
      )
    })

    const industryStore = useAnalyticsIndustryStore();
    const {
      resetStore,
      resetSearchForm,
      resetSortingForm,
    } = industryStore;

    const {
      mode,
      fetchMode,
      searchForm,
      sortingForm,
      selectedTemplatesIds,
    } = storeToRefs(industryStore);

    const {
      commonCards,
      entitiesLists,
      entitiesListsInfo,
      monthlyChart,
      regionChart,
      sortingPanels,
      resetEntity,
      fetchEntity,
      fetchAllAnalyse,
      resetFetchingData,
    } = useAnalytics('AnalyseIndustry');

    const {
      getDatePublicRange,
      cancelTemplateRequest,
      fetchCurrentTemplate,
    } = useSearchTemplates('AnalyseIndustry');

    provide("type", "AnalyseIndustry");

    function newAnalyse(showForm = true) {
      resetStore();
      resetBeforeFetching();
      showErrorMessage.value = false;
      showDatesWarning.value = false;
      showSearchForm.value = showForm;
    }

    function searchFormWrap() {
      showSearchForm.value = !showSearchForm.value;
    }

    function setShowSearchForm(value: boolean) {
      showSearchForm.value = value;
    }

    function getFetchData() {
      switch (fetchMode.value) {
        case 'newAnalyse':
          return tagsToIdList(searchForm.value, true);
        case 'byTemplate':
          return { templateIds: selectedTemplatesIds.value };
        default:
          return null;
      }
    }

    /** валидация данных */
    function checkFetchErrors(m: string, fetchData: any) {
      showErrorMessage.value = false;
      innerErrorChecking.value = true;

      useApi().analytics.fetchValidateFilters(fetchData)
        .then(() => {
          fetchMode.value = m;
          fetchAllAnalyse(fetchData, sortingForm.value)
          scrollIntoView("analytics-results", "start")
        })
        .catch((error) => {
          showError(getErrorMessage(error?.response?.status, error?.data, 'При загрузке данных произошла ошибка'))
          showSearchForm.value = true;
        })
        .finally(() => innerErrorChecking.value = false)
    }

    /** аналитика по выбранному шаблону */
    function fetchTemplateAnalyse(id: number, onMount?: boolean) {
      selectedTemplatesIds.value = [id];
      const fetchData = { templateIds: selectedTemplatesIds.value }

      showSearchForm.value = false
      showErrorMessage.value = false
      showDatesWarning.value = false
      templateLoading.value = true;

      resetSearchForm()
      if (!onMount) resetSortingForm()
      resetBeforeFetching()

      fetchCurrentTemplate(id)
        .then(template => {
          const sf = template.content
          const hasDates = sf?.datePublic?.dateFrom && sf?.datePublic?.dateTo
          searchForm.value = {
            ...sf,
            datePublic:
              hasDates
                ? sf.datePublic
                : getDatePublicRange(sf.datePublic?.dateFrom, sf?.datePublic?.dateTo),
          }

          if (hasDates) checkFetchErrors('byTemplate', fetchData)
          else {
            showDatesWarning.value = true
            checkFetchErrors('newAnalyse', tagsToIdList(searchForm.value, true))
          }
        })
        .catch((status) => {
          openErrorAlert(ErrorMessageMode.NotFoundError, status === 404 ? 'Возможно, шаблон был удален, пожалуйста, измените выбор в селекторе шаблонов' : '')
          if (status !== 404) checkFetchErrors('byTemplate', fetchData)
        })
        .finally(() => templateLoading.value = false)
    }

    /** аналитика по расширенным фильтрам */
    function startAnalyse(onMount?: boolean) {
      showDatesWarning.value = false
      selectedTemplatesIds.value = []
      if (!onMount) resetSortingForm()
      resetBeforeFetching()
      checkFetchErrors('newAnalyse', tagsToIdList(searchForm.value, true))
    }

    function fetchEntityPrev() {
      const fetchData = assignSorting(getFetchData(), sortingForm.value[mode.value])
      fetchEntity(fetchData, mode.value, "industry")
    }

    function resetBeforeFetching() {
      fetchMode.value = 'none'
      cancelTemplateRequest()
      resetFetchingData()
    }

    function downloadExcel() {
      const fetchData = assignSorting(getFetchData(), sortingForm.value[mode.value])

      downloadAnalyticExcel(excelLoader, fetchData, mode.value, 'industry', '')
    }

    /**
     * восстановление состояния при возврате на страницу
     * проверки после или стоят для того, чтобы обработать ситуацию, в которой при прошлом визите
     * пользователю была выведена ошибка валидации, которую он не исправил
     * (в таком случае fetchMode не будет содержать значения, но данные для загрузки будут)
     */
    onMounted(() => {

      if (searchForm.value.hasOwnProperty('regionIds')) delete searchForm.value.regionIds
      if (!searchForm.value.hasOwnProperty('places')) searchForm.value.places = []

      if (fetchMode.value === 'byTemplate' || selectedTemplatesIds.value.length) {
        // проверяем, что шаблон не был удален
        if (templatesStore.templatesList.find(e => e.id === selectedTemplatesIds.value[0])) {
          fetchTemplateAnalyse(selectedTemplatesIds.value[0], true)
        } else {
          newAnalyse(false)
        }
      } else if (fetchMode.value === 'newAnalyse' || isAdvancedModified.value) {
        showSearchForm.value = true
        startAnalyse(true)
      }
    })

    /** for cancelling pending requests
     * отмена ушедших запросов при уходе со страницы
     */
    onBeforeUnmount(() => {
      nextTick(() => {
        cancelTemplateRequest()
        resetFetchingData()
      })
    })

    const averageCards = [
      [{ label: "Общее количество закупок", valueNum: "lotsCount", compact: true, currency: false }],
      [{ label: "Общая сумма закупок", valueNum: "sumLotCost", compact: true, currency: true }],
      [{ label: "Средняя сумма закупки", valueNum: "averageLotCost", compact: true, currency: true }],
      [{ label: "Среднее снижение цены", valueNum: "averageReducePercentage", valueStr: "%", digits: true }],
      [{ label: "Среднее число заявок", valueNum: "averageAppsCount", digits: true }],
    ];

    const industryTabs = [
      { title: "Закупки", mode: "lots" },
      { title: "Заказчики", mode: "customers" },
      { title: "Поставщики", mode: "suppliers" },
    ]

    return {
      mode,
      showSearchForm,
      searchForm,
      sortingForm,
      enableTemplates,
      selectedTemplatesIds,
      fetchMode,
      averageCards,
      industryTabs,
      commonCards,
      entitiesLists,
      entitiesListsInfo,
      monthlyChart,
      regionChart,
      sortingPanels,
      isErrorsChecking,
      isAdvancedModified,
      excelLoader,
      searchFormActiveTab,
      errorMessage,
      showDatesWarning,
      showErrorMessage,
      setShowSearchForm,
      searchFormWrap,
      openErrorAlert,
      showError,
      resetEntity,
      fetchEntity,
      startAnalyse,
      newAnalyse,
      downloadExcel,
      fetchEntityPrev,
      fetchTemplateAnalyse,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/pages/main";

.industries {
  display: flex;
  flex-flow: column;
  gap: 32px;

  .selector-wrapper {
    display: flex;
    flex-flow: row;
    gap: 8px;
  }

  .nowrap {
    white-space: nowrap;
  }

  #analytics-results {
    display: flex;
    flex-flow: column;
    gap: 40px;
  }

  .show-form-button {
    font-size: 13px;
    color: inherit;

    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
}
</style>
